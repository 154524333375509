import { atom } from 'recoil'
import { IQuestionAnswer, ISurvey } from './surveys.types'

interface ISurveysState {
  items: ISurvey[]
  answerState: IQuestionAnswer[]
}
const surveysAtom = atom<ISurveysState>({
  key: 'surveys',
  default: { items: [], answerState: [] },
})
export default surveysAtom
