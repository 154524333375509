import css from '@/shared/Layouts/FormLayout/formLayout.module.css'
import HomeIcon from '@/public/IconHome.svg'
import ProductsIcon from '@/public/IconProducts.svg'
import OrdersIcon from '@/public/IconPackages.svg'
import SupportIcon from '@/public/IconSupport.svg'
import UserIcon from '@/public/profile.svg'
import { useRouter } from 'next/router'
import { memo } from 'react'
import { useIntl } from 'react-intl'

const Footer = () => {
  const intl = useIntl()
  const router = useRouter()

  const profileOpen = router.route === '/Profile'
  const mainOpen = router.route === '/'
  const ordersOpen = router.route === '/Orders'
  const supportOpen = router.route === '/Support'
  const productsOpen = router.route === '/Products'

  return (
    <div className={css.footer}>
      <div className={css.footerItem} data-open={mainOpen} onClick={() => router.push('/')}>
        <HomeIcon style={{ width: '24px', height: '24px' }} />
        <p className={css.footerItemTitle}>{intl.formatMessage({ id: 'footer.main' })}</p>
      </div>
      <div className={css.footerItem} data-open={productsOpen} onClick={() => router.push('/Products')}>
        <ProductsIcon style={{ width: '24px', height: '24px' }} />
        <p className={css.footerItemTitle}>{intl.formatMessage({ id: 'footer.products' })}</p>
      </div>
      <div className={css.footerItem} data-open={ordersOpen} onClick={() => router.push('/Orders')}>
        <OrdersIcon style={{ width: '24px', height: '24px' }} />
        <p className={css.footerItemTitle}>{intl.formatMessage({ id: 'footer.orders' })}</p>
      </div>
      <div className={css.footerItem} data-open={supportOpen} onClick={() => router.push('/Support')}>
        <SupportIcon style={{ width: '24px', height: '24px' }} />
        <p className={css.footerItemTitle}>{intl.formatMessage({ id: 'footer.support' })}</p>
      </div>
      <div className={css.footerItem} data-open={profileOpen} onClick={() => router.push('/Profile')}>
        <UserIcon style={{ width: '24px', height: '24px' }} />
        <p className={css.footerItemTitle}>{intl.formatMessage({ id: 'footer.profile' })}</p>
      </div>
    </div>
  )
}

export default memo(Footer)
