import { CSSProperties, PropsWithChildren, ReactNode } from 'react'
import css from './formLayout.module.css'
import BackIcon from '@/public/back.svg'
import Footer from '@/shared/Layouts/components/Footer'

interface IProps {
  onBackClick: () => void
  header?: string | ReactNode
  className?: string
  headerClassName?: string
  style?: CSSProperties
  headerStyle?: CSSProperties
  withFooter?: boolean
  iconsStyle?: CSSProperties
}

/** Empty full-width and full-height layout with go back button */
const FormLayout = (props: PropsWithChildren<IProps>) => {
  return (
    <div className={`${css.wrapper} ${props.className ? props.className : ''}`} style={props.style}>
      <div
        className={`${css.header} flex-space-between ${props.headerClassName ? props.headerClassName : ''}`}
        style={props.headerStyle}
      >
        <BackIcon onClick={props.onBackClick} data-test="back_button" style={props.iconsStyle} />
        {props.header &&
          (typeof props.header === 'string' ? <h2 className="text-700 text--grey">{props.header}</h2> : props.header)}
      </div>
      {props.children}
      {props.withFooter && <Footer />}
    </div>
  )
}

export default FormLayout
