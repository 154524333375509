import css from '@/shared/Layouts/FormLayout/formLayout.module.css'
import Logo from '@/public/mainLogo.svg'
import { useRouter } from 'next/router'
import { CSSProperties, PropsWithChildren } from 'react'
import Footer from '@/shared/Layouts/components/Footer'
import { useRecoilValue } from 'recoil'
import surveysAtom from '@/components/Surveys/surveys.atom'
import ArrowRight from '@/public/IconForward.svg'

const LogoLayout = (props: PropsWithChildren<{ withContentPadding?: boolean; headerStyle?: CSSProperties }>) => {
  const router = useRouter()
  const surveys = useRecoilValue(surveysAtom)
  const isSurveyAvailable = surveys.items.some((it) => !it.isAlreadyPassed)

  console.log(surveys, isSurveyAvailable)

  return (
    <div className={css.wrapper} data-withContentPadding={props.withContentPadding} style={{ paddingBottom: '70px' }}>
      {isSurveyAvailable && (
        <div className="sides-padding" onClick={() => router.push('/Surveys')}>
          <div className={css.surveyTile}>
            <p className="text--primary text-14px">Пожалуйста, пройдите опрос 😊</p>
            <ArrowRight style={{ stroke: 'var(--primary)', width: '24px', height: '24px' }} />
          </div>
        </div>
      )}
      <div
        className={`${css.header} flex-space-between`}
        data-withContentPadding={props.withContentPadding}
        style={props.headerStyle}
      >
        <Logo
          onClick={() => router.push('/')}
          style={{ transform: 'scale(0.5) translateX(-50%)', marginRight: 'auto', marginLeft: '20px' }}
        />
      </div>
      {props.children}
      <Footer />
    </div>
  )
}

export default LogoLayout
